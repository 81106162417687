<template>
  <div class="my-tabs">
    <v-tabs
      v-model="tab"
      background-color="cyan lighten-5"
      color="indigo darken-4"
      @change="cambioTab"
      class="prueba"
      slider-color="indigo darken-4"
      vertical
      height="100%"
      style="height: 100%"
    >
      <v-tab v-if="!free">STUDENT GUIDES</v-tab>
      <v-tab v-if="!free">Syllable type cards</v-tab>
      <v-tab>Phonics Cards</v-tab>
      <v-tab>READING WORDS</v-tab>
      <v-tab v-if="!free">Achievement Certificates</v-tab>
      <v-tab v-if="!free">Lee Lee Scope and sequence</v-tab>
      <v-tab v-if="!free">Lee Lee Phonics BINGO</v-tab>
      <v-tab v-if="!free">Other Resources</v-tab>
    <!-- </v-tabs>
    <v-tabs-items v-model="tab"> -->
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="300">
                  <v-img
                    src="../../../assets/admin/guia-estudio-k2.png"
                    height="350px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">
                    Guía del estudiante Lee Lee K-2
                  </p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Los sonidos
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlGuia1"
                      @click="SaveDownload('RCSSG01')"
                      :loading="loadingGuia1"
                      :disabled="loadingGuia1"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col class="mt-5" cols="6">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartillas-silabas-inversas-directas.png"
                    height="350px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0">
                    Sílabas Inversas
                  </p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sílabas inversas - directas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlSilabasInversasDirectas"
                      @click="SaveDownload('RCSSG02')"
                      :loading="loadingSilabasInversasDirectas"
                      :disabled="loadingSilabasInversasDirectas"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            <v-col class="mt-5" cols="6">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartillas-silabas-trabadas.png"
                    height="350px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0">
                    Sílabas Inversas
                  </p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sílabas trabadas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlSilabasTrabadas"
                      @click="SaveDownload('RCSSG03')"
                      :loading="loadingSilabasTrabadas"
                      :disabled="loadingSilabasTrabadas"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            <v-col class="mt-5" cols="6">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartillas-silabas-directas.png"
                    height="350px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0">
                    Sílabas Inversas
                  </p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sílabas directas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlSilabasDirectas"
                      @click="SaveDownload('RCSSG04')"
                      :loading="loadingSilabasDirectas"
                      :disabled="loadingSilabasDirectas"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5" cols="6">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartillas-silabas-inversas.png"
                    height="350px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0">
                    Sílabas Inversas
                  </p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sílabas inversas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlSilabasInversas"
                      @click="SaveDownload('RCSSG05')"
                      :loading="loadingSilabasInversas"
                      :disabled="loadingSilabasInversas"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/phonetic-articulation-cards.png"
                    height="230px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                   Phonetic articulation cards
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlPhoneticArticulationCards"
                      @click="SaveDownload('RCSPC01')"
                      :loading="loadingPhoneticArticulationCards"
                      :disabled="loadingPhoneticArticulationCards"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/dibujoyfigura.png"
                    height="230px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Figuras y Letras Completa
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlFiguraLetra"
                      @click="SaveDownload('RCSPC02')"
                      :loading="loading"
                      :disabled="loading"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <!-- <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                    <v-img src="../../../assets/admin/Imagen.png" height="230px"></v-img>
                        <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">Solo Figuras</p>
                    <v-card-actions>
                        <v-btn color="primary" width="100%" :href="urlFigura" @click="SaveDownload('Cartillas y tarjetas foneticas - Solo Figuras',2)" :loading="loading2" :disabled="loading2" target="_blank" dowload>
                            <v-icon>mdi-download</v-icon>
                            Download
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col> -->
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/Letra.png"
                    height="230px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Solo Letras Completa
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlLetra"
                      @click="SaveDownload('RCSPC03')"
                      :loading="loading3"
                      :disabled="loading3"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/dibujoyfigura.png"
                    height="230px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Figuras y Letras
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlFiguraLetraIndividual"
                      @click="SaveDownload('RCSPC04')"
                      :loading="loadingFigurayLetraIndividual"
                      :disabled="loadingFigurayLetraIndividual"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/Letra.png"
                    height="230px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Solo Letras
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlLetraIndividual"
                      @click="SaveDownload('RCSPC05')"
                      :loading="loadingLetraIndividual"
                      :disabled="loadingLetraIndividual"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item><!--READING WORDS-->
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/palabras2silabas.png"
                    height="190px"
                  ></v-img>                  
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Palabras de 2 sílabas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlPalabras2Silabas"
                      @click="SaveDownload('RCSRW01')"
                      :loading="loadingPalabras2Silabas"
                      :disabled="loadingPalabras2Silabas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/palabras2silabasdibujo.png"
                    height="190px"
                  ></v-img>                  
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Palabras de 2 sílabas con dibujo
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlPalabras2SilabasconDibujo"
                      @click="SaveDownload('RCSRW02')"
                      :loading="loadingPalabras2SilabasconDibujo"
                      :disabled="loadingPalabras2SilabasconDibujo"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/palabras3silabas.png"
                    height="190px"
                  ></v-img>                  
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Palabras de 3 sílabas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlPalabras3Silabas"
                      @click="SaveDownload('RCSRW03')"
                      :loading="loadingPalabras3Silabas"
                      :disabled="loadingPalabras3Silabas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/palabrassilabastrabadas.png"
                    height="190px"
                  ></v-img>                  
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Palabras con sílabas trabadas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlPalabrasconSilabasTrabadas"
                      @click="SaveDownload('RCSRW04')"
                      :loading="loadingPalabrasconSilabasTrabadas"
                      :disabled="loadingPalabrasconSilabasTrabadas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>  
          </v-container>
        </v-card>
      </v-tab-item><!--END READING WORDS-->
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM1.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 1</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sonidos Iniciales
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM1"
                      @click="SaveDownload('RCSAC01')"
                      :loading="loadingCertificadosM1"
                      :disabled="loadingCertificadosM1"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM2.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 2</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Las Sílabas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM2"
                      @click="SaveDownload('RCSAC02')"
                      :loading="loadingCertificadosM2"
                      :disabled="loadingCertificadosM2"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM3.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 3</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Las Palabras
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM3"
                      @click="SaveDownload('RCSAC03')"
                      :loading="loadingCertificadosM3"
                      :disabled="loadingCertificadosM3"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM4.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 4</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Extensiones, sílabas inversas, sílabas con inicio
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM4"
                      @click="SaveDownload('RCSAC04')"
                      :loading="loadingCertificadosM4"
                      :disabled="loadingCertificadosM4"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM5.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 5</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tres Sílabas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM5"
                      @click="SaveDownload('RCSAC05')"
                      :loading="loadingCertificadosM5"
                      :disabled="loadingCertificadosM5"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM6.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 6</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Sílabas trabadas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM6"
                      @click="SaveDownload('RCSAC06')"
                      :loading="loadingCertificadosM6"
                      :disabled="loadingCertificadosM6"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/certificadoM7.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Mision 7</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Lectura de Oraciones
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCertificadosM7"
                      @click="SaveDownload('RCSAC07')"
                      :loading="loadingCertificadosM7"
                      :disabled="loadingCertificadosM7"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/calendario-Pre-Kinder.png"
                    height="190px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0 ">Programa de ejecución del metodo</p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Pre-Kinder
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCronogramapk"
                      @click="SaveDownload('RCSSS01')"
                      :loading="loadingCronogramapk"
                      :disabled="loadingCronogramapk"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/calendario-Kinder.png"
                    height="190px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0 ">Programa de ejecución del metodo</p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Kinder
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCronogramak"
                      @click="SaveDownload('RCSSS02')"
                      :loading="loadingCronogramak"
                      :disabled="loadingCronogramak"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/calendario-1-2grade.png"
                    height="190px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0 ">Programa de ejecución del metodo</p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    1st. - 2nd. grade
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCronograma12"
                      @click="SaveDownload('RCSSS03')"
                      :loading="loadingCronograma12"
                      :disabled="loadingCronograma12"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/calendario-3-5grade.png"
                    height="190px"
                  ></v-img>

                  <!-- <p class="text-center text-h6 mb-0 ">Programa de ejecución del metodo</p> -->
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    3rd. - 5th. Grade
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlCronograma345"
                      @click="SaveDownload('RCSSS04')"
                      :loading="loadingCronograma345"
                      :disabled="loadingCronograma345"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-instrucciones.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Instrucciones
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoInstrucciones"
                      @click="SaveDownload('RCSPB01')"
                      :loading="loadingBingoInstrucciones"
                      :disabled="loadingBingoInstrucciones"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <div
              style="
                width: 80%;
                height: 20px;
                border-bottom: 1px solid black;
                text-align: center;
                margin: 20px auto 15px auto;
              "
            >
              <span
                style="font-size: 25px; background-color: #fff; padding: 0 20px"
              >
                Sonido de las letras<!--Padding is optional-->
              </span>
            </div>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-base.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Base Guia
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoBase"
                      @click="SaveDownload('RCSPB02')"
                      :loading="loadingBingoBase"
                      :disabled="loadingBingoBase"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-canto.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas para Cantar
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoCanto"
                      @click="SaveDownload('RCSPB03')"
                      :loading="loadingBingoCanto"
                      :disabled="loadingBingoCanto"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-tarjetas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas de Bingo
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoTarjetas"
                      @click="SaveDownload('RCSPB04')"
                      :loading="loadingBingoTarjetas"
                      :disabled="loadingBingoTarjetas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <div
              style="
                width: 80%;
                height: 20px;
                border-bottom: 1px solid black;
                text-align: center;
                margin: 20px auto 15px auto;
              "
            >
              <span
                style="font-size: 25px; background-color: #fff; padding: 0 20px"
              >
                Sílabas inversas<!--Padding is optional-->
              </span>
            </div>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-base-silabas-inversas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Base Guia
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoBaseSilabasInversas"
                      @click="
                        SaveDownload('RCSPB05')
                      "
                      :loading="loadingBingoBaseSilabasInversas"
                      :disabled="loadingBingoBaseSilabasInversas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-canto-silabas-inversas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas para Cantar
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoCantoSilabasInversas"
                      @click="SaveDownload('RCSPB06')
                      "
                      :loading="loadingBingoCantoSilabasInversas"
                      :disabled="loadingBingoCantoSilabasInversas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/bingo-tarjetas-silabas-inversas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas de Bingo
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoTarjetasSilabasInversas"
                      @click="SaveDownload('RCSPB07')"
                      :loading="loadingBingoTarjetasSilabasInversas"
                      :disabled="loadingBingoTarjetasSilabasInversas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <div
              style="
                width: 80%;
                height: 20px;
                border-bottom: 1px solid black;
                text-align: center;
                margin: 20px auto 15px auto;
              "
            >
              <span
                style="font-size: 25px; background-color: #fff; padding: 0 20px"
              >
                Sílabas directas<!--Padding is optional-->
              </span>
            </div>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/base-para-bingo-silabas-directas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Base Guia
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoBaseSilabasDirectas"
                      @click="SaveDownload('RCSPB08')"
                      :loading="loadingBingoBaseSilabasDirectas"
                      :disabled="loadingBingoBaseSilabasDirectas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartas-para-cantar-silabas-directas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas para Cantar
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoCantoSilabasDirectas"
                      @click="SaveDownload('RCSPB09')"
                      :loading="loadingBingoCantoSilabasDirectas"
                      :disabled="loadingBingoCantoSilabasDirectas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/cartas-silabas-directas.png"
                    height="190px"
                  ></v-img>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Tarjetas de Bingo
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlBingoTarjetasSilabasDirectas"
                      @click="
                        SaveDownload('RCSPB10')
                      "
                      :loading="loadingBingoTarjetasSilabasDirectas"
                      :disabled="loadingBingoTarjetasSilabasDirectas"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    src="../../../assets/admin/otrosrecursos1.png"
                    height="190px"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">Encuentra las Palabras</p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    2 sílabas
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlOther1"
                      @click="SaveDownload('RCSOR01')"
                      :loading="loadingOther1"
                      :disabled="loadingOther1"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="mt-5">
                <v-card class="mx-auto" max-width="250">                  
                  <v-img
                    src="../../../assets/admin/otrosrecursos2.png"
                    height="190px"
                  ></v-img>
                  <p class="text-center text-h6 mb-0">
                    Caligrafía y lectura mediante trazos
                  </p>
                  <p
                    class="
                      text-center
                      subtitle-1
                      cyan
                      lighten-5
                      mb-0
                      font-weight-bold
                    "
                  >
                    Letras-Sílabas-oraciones
                  </p>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      width="100%"
                      :href="urlOther2"
                      @click="
                        SaveDownload('RCSOR02')
                      "
                      :loading="loadingOther2"
                      :disabled="loadingOther2"
                      target="_blank"
                      dowload
                    >
                      <v-icon>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    <!-- </v-tabs-items> -->
    </v-tabs>
  </div>
</template>
<script>
import { auth, db } from "../../../plugins/firebase";
import firebase from "firebase";
export default {
  data() {
    return {
      tab: "",
      urlFiguraLetra: "",
      loading: true,
      urlFiguraLetraIndividual: "",
      loadingFigurayLetraIndividual: true,
      urlLetraIndividual: "",
      loadingLetraIndividual: true,
      urlPhoneticArticulationCards: "",
      loadingPhoneticArticulationCards: true,
      urlFigura: "",
      loading2: true,
      urlLetra: "",
      loading3: true,
      loadingOther1: true,
      urlOther1: "",
      loadingOther2: true,
      urlOther2: "",
      urlCertificadosM1: "",
      loadingCertificadosM1: true,
      urlCertificadosM2: "",
      loadingCertificadosM2: true,
      urlCertificadosM3: "",
      loadingCertificadosM3: true,
      urlCertificadosM4: "",
      loadingCertificadosM4: true,
      urlCertificadosM5: "",
      loadingCertificadosM5: true,
      urlCertificadosM6: "",
      loadingCertificadosM6: true,
      urlCertificadosM7: "",
      loadingCertificadosM7: true,

      urlCronograma12: "",
      loadingCronograma12: true,
      urlCronograma345: "",
      loadingCronograma345: true,
      urlCronogramapk: "",
      loadingCronogramapk: true,
      urlCronogramak: "",
      loadingCronogramak: true,

      urlPalabras2Silabas: "",
      loadingPalabras2Silabas: true,
      urlPalabras2SilabasconDibujo: "",
      loadingPalabras2SilabasconDibujo: true,
      urlPalabras3Silabas: "",
      loadingPalabras3Silabas: true,
      urlPalabrasconSilabasTrabadas: "",
      loadingPalabrasconSilabasTrabadas: true,

      urlBingoInstrucciones: "",
      loadingBingoInstrucciones: true,
      urlBingoBase: "",
      loadingBingoBase: true,
      urlBingoTarjetas: "",
      loadingBingoTarjetas: true,
      urlBingoCanto: "",
      loadingBingoCanto: true,
      urlBingoBaseSilabasInversas: "",
      loadingBingoBaseSilabasInversas: true,
      urlBingoTarjetasSilabasInversas: "",
      loadingBingoTarjetasSilabasInversas: true,
      urlBingoCantoSilabasInversas: "",
      loadingBingoCantoSilabasInversas: true,
      urlBingoBaseSilabasDirectas: "",
      loadingBingoBaseSilabasDirectas: true,
      urlBingoCantoSilabasDirectas: "",
      loadingBingoCantoSilabasDirectas: true,
      urlBingoTarjetasSilabasDirectas: "",
      loadingBingoTarjetasSilabasDirectas: true,

      urlGuia1: "",
      loadingGuia1: true,
      urlSilabasInversasDirectas: "",
			loadingSilabasInversasDirectas: true,
			urlSilabasTrabadas: "",
			loadingSilabasTrabadas: true,
      urlSilabasDirectas: "",
			loadingSilabasDirectas: true,
			urlSilabasInversas: "",
			loadingSilabasInversas: true,
      free: true,
      resources: [
        {"ResourceID": "RCSSG01",	"Title": "Guía del estudiante Lee Lee K-2",	"Doc": "Guia del estudiante K-2 Los sonidos de las letras.pdf",	"Cat":"STUDENT GUIDES"},
        {"ResourceID": "RCSSG02", "Title": "Sílabas inversas - directas", "Doc": "Cartilla de Silabas Inversas – Directas.pdf",  "Cat": "Syllable type cards"},
        {"ResourceID": "RCSSG03", "Title": "Sílabas trabadas", "Doc": "Cartilla de Silabas Trabadas.pdf",  "Cat": "Syllable type cards"},
        {"ResourceID": "RCSSG04", "Title": "Sílabas directas", "Doc": "Cartilla silabas directas.pdf",  "Cat": "Syllable type cards"},
        {"ResourceID": "RCSSG05", "Title": "Sílabas inversas", "Doc": "Cartilla Silabas Inversas.pdf",  "Cat": "Syllable type cards"},
        {"ResourceID": "RCSPC01", "Title": "Phonetic articulation cards", "Doc": "Phonetic articulation cards.pdf",  "Cat": "Phonics Cards"},
        {"ResourceID": "RCSPC02", "Title": "Figuras y Letras Completa", "Doc": "Cartilla con letras y dibulos completa.pdf",  "Cat": "Phonics Cards"},
        {"ResourceID": "RCSPC03", "Title": "Solo Letras Completa", "Doc": "Cartilla solo letras completa.pdf",  "Cat": "Phonics Cards"},
        {"ResourceID": "RCSPC04", "Title": "Figuras y Letras", "Doc": "Cartilla con letras y dibujos individual.pdf",  "Cat": "Phonics Cards"},
        {"ResourceID": "RCSPC05", "Title": "Solo Letras", "Doc": "",  "Cat": "Phonics Cards"},

        {"ResourceID": "RCSRW01", "Title": "Palabras de 2 sílabas", "Doc": "Lee Lee Palabras de 2 Sílabas.pdf",  "Cat": "Reading Words"},
        {"ResourceID": "RCSRW02", "Title": "Palabras de 2 sílabas con dibujo", "Doc": "Lee Lee Palabras de 2 Sílabas con Dibujo.pdf",  "Cat": "Reading Words"},
        {"ResourceID": "RCSRW03", "Title": "Palabras de 3 sílabas", "Doc": "Lee Lee Palabras 3 sílabas.pdf",  "Cat": "Reading Words"},
        {"ResourceID": "RCSRW04", "Title": "Palabras con sílabas trabadas", "Doc": "Lee Lee Palabras con Sílabas Trabadas.pdf",  "Cat": "Reading Words"},

        {"ResourceID": "RCSAC01", "Title": "Mision 1 Sonidos Iniciales", "Doc": "Mision 1.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC02", "Title": "Mision 2 Las Sílabas", "Doc": "Mision 2.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC03", "Title": "Mision 3 Las Palabras", "Doc": "Mision 3.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC04", "Title": "Mision 4 Extensiones, sílabas inversas, sílabas con inicio", "Doc": "Mision 4.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC05", "Title": "Mision 5 Tres Sílabas", "Doc": "Mision 5.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC06", "Title": "Mision 6 Sílabas trabadas", "Doc": "Mision 6.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSAC07", "Title": "Mision 7 Lectura de Oraciones", "Doc": "Mision 7.pdf",  "Cat": "Achievement Certificates"},
        {"ResourceID": "RCSSS01", "Title": "Pre-Kinder", "Doc": "Programa de tiempos de Ensenanza Lee Lee PK.pdf",  "Cat": "Lee Lee Scope and sequence"},
        {"ResourceID": "RCSSS02", "Title": "Kinder", "Doc": "Programa de tiempos de Ensenanza Lee Lee Kinder.pdf",  "Cat": "Lee Lee Scope and sequence"},
        {"ResourceID": "RCSSS03", "Title": "1st. - 2nd. grade", "Doc": "Programa de tiempos de Ensenanza Lee Lee 1st and 2nd grade.pdf",  "Cat": "Lee Lee Scope and sequence"},
        {"ResourceID": "RCSSS04", "Title": "3rd. - 5th. Grade", "Doc": "Programa de tiempos de Ensenanza Lee Lee 3rd – 5th grade.pdf",  "Cat": "Lee Lee Scope and sequence"},
        {"ResourceID": "RCSPB01", "Title": "Instrucciones", "Doc": "Instrucciones.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB02", "Title": "Base Guia", "Doc": "Base bingo sonidos de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB03", "Title": "Tarjetas para Cantar", "Doc": "Tarjetas para cantar Sonido de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB04", "Title": "Tarjetas de Bingo", "Doc": "Cartillas bingo Sonido de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB05", "Title": "Base Guia", "Doc": "Base Bingo Silabas Inversas.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB06", "Title": "Tarjetas para Cantar", "Doc": "Tarjetas para cantar el bingo.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB07", "Title": "Tarjetas de Bingo", "Doc": "Bingo Silabas Inversas Cartillas.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB08", "Title": "Base Guia", "Doc": "BASE PARA BINGO SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB09", "Title": "Tarjetas para Cantar", "Doc": "CARTAS PARA CANTAR SILABAS BINGO SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSPB10", "Title": "Tarjetas de Bingo", "Doc": "CARTAS SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO"},
        {"ResourceID": "RCSOR01", "Title": "Encuentra las Palabras 2 sílabas", "Doc": "Encuentra las palabras 2 silabas.pdf",  "Cat": "Other Resources"},
        {"ResourceID": "RCSOR02", "Title": "Caligrafía y lectura mediante trazos Letras-Sílabas-oraciones", "Doc": "Trazar letras_silabas y oraciones.pdf",  "Cat": "Other Resources"}
      ]
    };
  },
  created() {},
  methods: {
    async GetDataTeacher() {
      let iud = this.$store.state.user.uid;
      let role = this.$store.state.user.role;
      let info = "";
      if (role == "individualTeacher") {
        info = await db.collection("IndividualTeacher").doc(iud).get();
        console.log(info.data());

        if (info.data().Full) {
          this.free = false;
        }
      } else {
        this.free = false;
      }
    },
    OtrosRecursos() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Encuentra las palabras 2 silabas.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loadingOther1 = false;
          aux.urlOther1 = url;
        });
    },
    OtrosRecursos2() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Trazar letras_silabas y oraciones.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loadingOther2 = false;
          aux.urlOther2 = url;
        });
    },
    DownloadImagenLetra() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Cartilla con letras y dibulos completa.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          //console.log(url)
          //window.open(url, '_blank').focus();
          aux.loading = false;
          aux.urlFiguraLetra = url;
          // var link = document.createElement("a");
          // if (link.download !== undefined) {
          //     link.setAttribute("href", url);
          //     link.setAttribute('download','Cartilla y Tarjetas foneticas-Figuras y Letras.pdf');
          //     link.setAttribute("target", "_blank");
          //     link.style.visibility = 'hidden';
          //     document.body.appendChild(link);
          //     link.click();
          //     document.body.removeChild(link);
          // }
        });
    },
    DownloadImagenLetraIndividual() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Cartilla con letras y dibujos individual.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loadingFigurayLetraIndividual = false;
          aux.urlFiguraLetraIndividual = url;
        });
    },
    DownloadPhoneticArticulationCards() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Phonetic articulation cards.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loadingPhoneticArticulationCards= false;
          aux.urlPhoneticArticulationCards = url;
        });
    },
    DownloadLetraIndividual() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload =
        "gs://backup2022-3cc18.appspot.com/Resources/Cartilla solo letras individual.pdf";
      storage
        .refFromURL(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loadingLetraIndividual = false;
          aux.urlFiguraLetraIndividual = url;
        });
    },
    DownloadLetra() {
      let aux = this;
      var storage = firebase.storage();
      let urlDownload = "Resources/Cartilla solo letras completa.pdf";
      storage
        .ref(urlDownload)
        .getDownloadURL()
        .then(function (url) {
          aux.loading3 = false;
          aux.urlLetra = url;
        });
    },
    Certificados() {
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Mision 1.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM1 = false;
          aux.urlCertificadosM1 = url;
        });
      storage
        .ref("Resources/Mision 2.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM2 = false;
          aux.urlCertificadosM2 = url;
        });
      storage
        .ref("Resources/Mision 3.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM3 = false;
          aux.urlCertificadosM3 = url;
        });
      storage
        .ref("Resources/Mision 4.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM4 = false;
          aux.urlCertificadosM4 = url;
        });
      storage
        .ref("Resources/Mision 5.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM5 = false;
          aux.urlCertificadosM5 = url;
        });
      storage
        .ref("Resources/Mision 6.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM6 = false;
          aux.urlCertificadosM6 = url;
        });
      storage
        .ref("Resources/Mision 7.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCertificadosM7 = false;
          aux.urlCertificadosM7 = url;
        });
    },
    readingWords() {
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Lee Lee Palabras de 2 Sílabas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingPalabras2Silabas = false;
          aux.urlPalabras2Silabas = url;
        });
      storage
        .ref("Resources/Lee Lee Palabras de 2 Sílabas con Dibujo.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingPalabras2SilabasconDibujo = false;
          aux.urlPalabras2SilabasconDibujo = url;
        });
      storage
        .ref("Resources/Lee Lee Palabras 3 sílabas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingPalabras3Silabas = false;
          aux.urlPalabras3Silabas = url;
        });
      storage
        .ref("Resources/Lee Lee Palabras con Sílabas Trabadas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingPalabrasconSilabasTrabadas = false;
          aux.urlPalabrasconSilabasTrabadas = url;
        });      
    },
    Calendarios() {
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Programa de tiempos de Ensenanza Lee Lee PK.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCronogramapk = false;
          aux.urlCronogramapk = url;
        });
      storage
        .ref("Resources/Programa de tiempos de Ensenanza Lee Lee Kinder.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCronogramak = false;
          aux.urlCronogramak = url;
        });
      storage
        .ref(
          "Resources/Programa de tiempos de Ensenanza Lee Lee 1st and 2nd grade.pdf"
        )
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCronograma12 = false;
          aux.urlCronograma12 = url;
        });
      storage
        .ref(
          "Resources/Programa de tiempos de Ensenanza Lee Lee 3rd - 5th grade.pdf"
        )
        .getDownloadURL()
        .then(function (url) {
          aux.loadingCronograma345 = false;
          aux.urlCronograma345 = url;
        });
    },
    Bingo() {
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Instrucciones.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoInstrucciones = false;
          aux.urlBingoInstrucciones = url;
        });
      storage
        .ref("Resources/Base bingo sonidos de las letras.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoBase = false;
          aux.urlBingoBase = url;
        });
      storage
        .ref("Resources/Tarjetas para cantar Sonido de las letras.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoCanto = false;
          aux.urlBingoCanto = url;
        });
      storage
        .ref("Resources/Cartillas bingo Sonido de las letras.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoTarjetas = false;
          aux.urlBingoTarjetas = url;
        });

      storage
        .ref("Resources/Base Bingo Silabas Inversas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoBaseSilabasInversas = false;
          aux.urlBingoBaseSilabasInversas = url;
        });
      storage
        .ref("Resources/Tarjetas para cantar el bingo.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoCantoSilabasInversas = false;
          aux.urlBingoCantoSilabasInversas = url;
        });
      storage
        .ref("Resources/Bingo Silabas Inversas Cartillas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoTarjetasSilabasInversas = false;
          aux.urlBingoTarjetasSilabasInversas = url;
        });
      storage
        .ref("Resources/BASE PARA BINGO SILABAS DIRECTAS.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoBaseSilabasDirectas = false;
          aux.urlBingoBaseSilabasDirectas = url;
        });
      storage
        .ref("Resources/CARTAS PARA CANTAR SILABAS BINGO SILABAS DIRECTAS.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoCantoSilabasDirectas = false;
          aux.urlBingoCantoSilabasDirectas = url;
        });
      storage
        .ref("Resources/CARTAS SILABAS DIRECTAS.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingBingoTarjetasSilabasDirectas = false;
          aux.urlBingoTarjetasSilabasDirectas = url;
        });  
    },
    Guias() {
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Guia del estudiante K-2 Los sonidos de las letras.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingGuia1 = false;
          aux.urlGuia1 = url;
        });
    },
    CartillasSilabas(){
      let aux = this;
      var storage = firebase.storage();
      storage
        .ref("Resources/Cartilla de Silabas Inversas - Directas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingSilabasInversasDirectas = false;
          aux.urlSilabasInversasDirectas = url;
        });
			storage
        .ref("Resources/Cartilla de Silabas Trabadas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingSilabasTrabadas = false;
          aux.urlSilabasTrabadas = url;
        });
			storage
        .ref("Resources/Cartilla silabas directas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingSilabasDirectas = false;
          aux.urlSilabasDirectas = url;
        });
			storage
        .ref("Resources/Cartilla Silabas Inversas.pdf")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingSilabasInversas = false;
          aux.urlSilabasInversas = url;
        });
    },
    async SaveDownload(ResourceID) {
      //console.log(titulo);
      let rol = this.$store.state.user.role;
      let iud = this.$store.state.user.uid;
      let dia = new Date();
      let time = dia.getTime();//console.log(dia.toISOString());
      let pos=this.resources.findIndex(resource => resource.ResourceID === ResourceID);
      if (rol == "schoolTeacher") {
        await db.collection("SchoolTeacher")
          .doc(iud)
          .update({            
            [`Downloads.${ResourceID}.Downloads`]: firebase.firestore.FieldValue.increment(1),
            [`Downloads.${ResourceID}.Title`]: this.resources[pos].Title,
            [`Downloads.${ResourceID}.Category`]: this.resources[pos].Cat,
            [`Downloads.${ResourceID}.Date`]: time
          });
      } else {
        await db.collection("IndividualTeacher")
          .doc(iud)
          .update({
            [`Downloads.${ResourceID}.Downloads`]: firebase.firestore.FieldValue.increment(1),
            [`Downloads.${ResourceID}.Title`]: this.resources[pos].Title,
            [`Downloads.${ResourceID}.Date`]: time
          });
      }
    },
    /*SaveDownload(titulo, indice) {
      console.log(titulo);
      let rol = this.$store.state.user.role;
      let iud = this.$store.state.user.uid;
      let dia = new Date();
      let time = dia.getTime();
      if (rol == "schoolTeacher") {
        db.collection("SchoolTeacher")
          .doc(iud)
          .update({
            [`Downloads.${time}`]: { Titulo: titulo, Indice: indice },
          });
      } else {
        db.collection("IndividualTeacher")
          .doc(iud)
          .update({
            [`Downloads.${time}`]: { Titulo: titulo, Indice: indice },
          });
      }
    },*/
    cambioTab() {
      //console.log('cambio de tab' + this.tab);
      switch (this.tab) {
        case 0:
          //console.log('entro a la primera')
          break;
        case 1:
          //console.log('entro a la segunda')
          //this.openModalListStudents();
          break;
        case 2:
          //console.log('entro a la tercera')
          //   this.openModalQR();
          break;
      }
    },
  },
  mounted() {
    this.GetDataTeacher();
    this.DownloadImagenLetra();
    this.DownloadImagenLetraIndividual();
    this.DownloadLetraIndividual();
    this.DownloadPhoneticArticulationCards();
    this.DownloadLetra();
    this.OtrosRecursos();
    this.OtrosRecursos2();
    this.Certificados();
    this.readingWords();
    this.Calendarios();
    this.Bingo();
    this.Guias();
    this.CartillasSilabas();
  },
};
</script>
<style lang="scss">
.style-1 {
  text-align: center;
  background-color: #e0f7fa;
}
.style-1 {
  del {
    color: rgba(red, 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(red, 0.8);
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-7deg);
    }
  }
  ins {
    color: green;
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em 0.5em;
  }
}

.child {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  /* Text */

  p {
    position: absolute;
    top: 20px;
    right: 0;
    color: white;
    transform: rotate(45deg);
    margin: 0;
    line-height: 1;
    font-size: 15px;
    text-transform: uppercase;
  }
}
.child::before {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  border-top: solid 40px rgb(200, 30, 50);
  border-right: solid 40px rgb(200, 30, 50);
  border-left: solid 40px transparent;
  border-bottom: solid 40px transparent;
}
.my-tabs{
  height: 100%;  
}
div.my-tabs div[role="tablist"]{
   display: flex; 
   align-items: center;
}
.my-tabs div.v-tabs-items {
    overflow-y:auto;
}
</style>